//
// Masthead
//

#Masthead {
  padding-top: 10rem;
  padding-bottom: calc(10rem - #{$navbar-height});

  background: linear-gradient(
      to bottom,
      #{fade-out($brown, 0.2)} 0%,
      #{fade-out($brown, 0.2)} 100%
    ),
    url('../../assets/img/bg-masthead.jpg');

  background-position: center;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;

  h1 {
    font-size: 2.25rem;
  }

  @include media-breakpoint-up(lg) {
    height: 100vh;
    min-height: 40rem;
    padding-top: $navbar-height;
    padding-bottom: 0;

    p {
      font-size: 1.15rem;
    }

    h1 {
      font-size: 3rem;
    }
  }

  @include media-breakpoint-up(xl) {
    h1 {
      font-size: 3.5rem;
    }
  }
}
