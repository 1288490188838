//
// Global
//

html {
  height: 100%;
  scroll-padding-top: calc(#{$navbar-height} - 1px);
}

body {
  width: 100%;
  height: 100%;
}

.page-section {
  padding: 8rem 0;
}

.text-white-75 {
  color: fade-out($white, 0.25) !important;
}
