//
// Colors
//

//- Override and add to color system
$orange: #f4623a;
$brown: #6b5a4ee7;
$maroon: #851b2b;
$green: #0f5132;
$red: #842029;

//- Override primary color
$primary: $maroon;
$success: $green;
$danger: $red;

$min-contrast-ratio: 3;
